import css from 'styled-jsx/css';
import {
    colorWhite, colorBorder, colorBtn,
    colorText
} from '@/utils/constants/colors';

const globalRadio = css.global`
.custom.ant-radio-wrapper {
    margin-right: 48px;
    color: ${colorText};
}
.custom.ant-radio-wrapper .ant-radio-inner::after {
    background-color: ${colorWhite};
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
}
.custom.ant-radio-wrapper .ant-radio-inner {
    background-color: ${colorWhite};
    border-color: ${colorBorder};
}
.custom.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background-color: ${colorBtn};
    border-color: ${colorBtn}; 
}
.custom.ant-radio-wrapper .ant-radio-wrapper:hover .ant-radio,
.custom.ant-radio-wrapper .ant-radio:hover .ant-radio-inner,
.custom.ant-radio-wrapper .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${colorBtn};
}
.custom.ant-radio-wrapper .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px rgba(170, 153, 231, 0.08);
}
.custom.ant-radio-wrapper .ant-radio-checked::after {
    border: none;
}
.custom.ant-radio-wrapper span.ant-radio + * {
    padding-left: 12px;
    padding-right: 0px;
}
`;

export default globalRadio;
