import css from 'styled-jsx/css';
import { colorWhite, colorBtn, colorText } from '@/utils/constants/colors';

const globalPaginationStyles = css.global`
    .custom.ant-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .custom.ant-pagination .ant-pagination-item {
        min-width: 24px;
        width: 24px;
        height: 24px;
        color: ${colorText};
        font-size: 14px;
        font-weight: bold;
        line-height: 18px;
        text-align: center;
        border-radius: 50%;
        border: none;
        margin-right: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .custom.ant-pagination .ant-pagination-item a {
        color: ${colorText};
    }

    .custom.ant-pagination .ant-pagination-item:focus a, 
    .custom.ant-pagination .ant-pagination-item:hover a {
        color: ${colorBtn};
    }

    .custom.ant-pagination .ant-pagination-item.ant-pagination-item-active {
        background-color: ${colorBtn};
    }

    .custom.ant-pagination .ant-pagination-item-active a, 
    .custom.ant-pagination .ant-pagination-item-active:focus a, 
    .custom.ant-pagination .ant-pagination-item-active:hover a {
        color: ${colorWhite};
    }

    .custom.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
        color: ${colorBtn};
    }

    .custom.ant-pagination .ant-pagination-prev,
    .custom.ant-pagination .ant-pagination-next,
    .custom.ant-pagination .ant-pagination-jump-prev,
    .custom.ant-pagination .ant-pagination-jump-next {
        color: ${colorText};
    }

    .custom.ant-pagination .ant-pagination-disabled a,
    .custom.ant-pagination .ant-pagination-disabled:hover a,
    .custom.ant-pagination .ant-pagination-disabled:focus a,
    .custom.ant-pagination .ant-pagination-disabled .ant-pagination-item-link,
    .custom.ant-pagination .ant-pagination-disabled:hover .ant-pagination-item-link,
    .custom.ant-pagination .ant-pagination-disabled:focus .ant-pagination-item-link {
        border: none;
        opacity: 0.5;
    }

    .custom.ant-pagination .ant-pagination-prev .ant-pagination-item-link,
    .custom.ant-pagination .ant-pagination-next .ant-pagination-item-link {
        border: none;
    }

    .custom.ant-pagination .ant-pagination-prev:focus .ant-pagination-item-link, 
    .custom.ant-pagination .ant-pagination-next:focus .ant-pagination-item-link, 
    .custom.ant-pagination .ant-pagination-prev:hover .ant-pagination-item-link, 
    .custom.ant-pagination .ant-pagination-next:hover .ant-pagination-item-link {
        color: ${colorBtn};
    }
`;

export default globalPaginationStyles;
