import css from 'styled-jsx/css';
import { colorText, colorBtn } from '@/utils/constants/colors';

export default css.global`
html {
    --antd-wave-shadow-color: ${colorBtn} !important;
}
body, p {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: ${colorText};
    margin: 0;
}
* {
    font-family: Quicksand;
}

html, body {
    min-height: 100%;
}
a {
    color: ${colorText};
    text-decoration: none;
}

a:hover {
    color: ${colorText};
}

*::selection {
    color: ${colorBtn}
}

img {
    width: 100%;
    height: 100%;
    user-select: none;
    -webkit-user-drag: none;
}
.v-center {
    display: flex;
    align-items: center;
}

.h-center {
    display: flex;
    justify-content: center;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

svg {
    display: block;
}
`;
