import { store } from 'react-notifications-component';

const DEFAULT_CONFIG = {
    container: 'top-center',
    showIcon: true,
    insert: 'top',
    dismiss: {
        showIcon: true,
        duration: 3000
    },
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut']
};

const notification = {
    error(message) {
        store.addNotification({
            ...DEFAULT_CONFIG,
            type: 'danger',
            message,
        });
    },
    info(message) {
        store.addNotification({
            ...DEFAULT_CONFIG,
            type: 'info',
            message
        });
    },
    success(message) {
        store.addNotification({
            ...DEFAULT_CONFIG,
            type: 'success',
            message,
        });
    }
};

export default notification;
