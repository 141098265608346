export const colorTitle = '#204774';

export const colorText = '#6b84a6';

export const colorBorder = '#a4aebc';

export const colorLightBorder = 'rgba(107, 132, 166, .3)';

export const colorBtn = '#aa99e7';

export const colorSuccess = '#4fceb9';

export const colorInfo = '#32b9fc';

export const colorDanger = '#ec5c3b';

export const colorWhite = '#fff';

export const colorBg = colorBorder;

export const colorLightBg = 'rgba(107, 132, 166, .2)';

export const containerShadow = `-8px 20px 30px rgba(210, 217, 236, .5), 8px 0 30px 4px  rgba(210, 217, 236, .5)`;
