import css from 'styled-jsx/css';
import { colorText, colorLightBorder } from '@/utils/constants/colors';

const globalSelectStyles = css.global`
.custom.ant-select {
    width: 100%;
}

.custom.ant-select .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 65px;
    width: 100%;
    border: 1px solid ${colorLightBorder};
    border-radius: 32px;
    color: ${colorText};
    padding: 20px 24px;
    box-shadow: none !important;
}

.custom.ant-select .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
}

.custom.ant-select .ant-select-arrow {
    width: 12px;
    height: 12px;
    right: 24.5px;
    color: #6B84A6;
}

.custom.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${colorLightBorder} !important;
}
`;

export default globalSelectStyles;
