import React from 'react';
import { Router, Route } from 'dva/router';
import dynamic from 'dva/dynamic';
import ReactNotification from 'react-notifications-component';
import globalRadio from '@/components/Radio';
import globalCheckBox from '@/components/CheckBox';
import globalPaginationStyles from '@/components/Pagination';
import globalDropdownStyles from '@/components/Dropdown';
import globalModalStyles from '@/components/Modal';
import globalInputStyles from '@/components/Input';
import globalSelectStyles from '@/components/Select';
import globalCollapse from '@/components/Collapse';
import globalNotificationStyles from '@/components/Notification';
import gloablStyles from './style';
import routes from './routes';
import 'react-notifications-component/dist/theme.css';

function App({ app, history }) {
    return (
        <>
            <style jsx>{gloablStyles}</style>
            <style jsx>{globalRadio}</style>
            <style jsx>{globalCheckBox}</style>
            <style jsx>{globalPaginationStyles}</style>
            <style jsx>{globalDropdownStyles}</style>
            <style jsx>{globalModalStyles}</style>
            <style jsx>{globalInputStyles}</style>
            <style jsx>{globalSelectStyles}</style>
            <style jsx>{globalCollapse}</style>
            <style jsx>{globalNotificationStyles}</style>
            <ReactNotification />
            <Router history={history}>
                <>
                    {
                        routes.map((item, index) => (
                            <Route
                                key={index}
                                path={item.path}
                                exact={item.exact === undefined ? true : item.exact}
                                component={dynamic({
                                    app,
                                    ...item
                                })}
                            />
                        ))
                    }
                </>
            </Router>
        </>
    );
}

export default App;
