import { USER_INFO_STORAGE_NAME, USER_TOKEN_STORAGE_NAME } from './constants/users';

export function getLocalUserInfo() {
    const userInfoJSON = localStorage.getItem(USER_INFO_STORAGE_NAME);
    if (userInfoJSON) {
        const userInfo = JSON.parse(userInfoJSON);
        return userInfo;
    }

    return {};
}

export function updateLocalUserInfo(userInfo) {
    const oldUserInfo = getLocalUserInfo();

    const newUserInfo = {
        ...oldUserInfo,
        ...userInfo
    };

    localStorage.setItem(USER_INFO_STORAGE_NAME, JSON.stringify(newUserInfo));
}

export function clearLocalUserInfo() {
    localStorage.removeItem(USER_INFO_STORAGE_NAME);
}

export function getLocalToken() {
    return localStorage.getItem(USER_TOKEN_STORAGE_NAME);
}

export function updateLocalToken(token) {
    return localStorage.setItem(USER_TOKEN_STORAGE_NAME, token);
}

export function clearLocalToken() {
    localStorage.removeItem(USER_TOKEN_STORAGE_NAME);
}

export function isLogin() {
    return getLocalToken();
}
