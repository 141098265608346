import request from '@/utils/request';
import {
    updateLocalUserInfo, getLocalUserInfo, clearLocalUserInfo, getLocalToken,
    updateLocalToken, clearLocalToken
} from '@/utils/user';
import API from '@/utils/request/api';
import notification from '@/utils/notification';
import { getUserInfo } from '@/services/user';
import { historyPush } from '@/utils/history';

const globalModel = {

    namespace: 'global',

    state: {
        userInfo: {},
        token: '',
    },

    reducers: {
        save(state, action) {
            return { ...state, ...action.payload };
        },
    },

    effects: {
        * getUserInfo(_, { call, put }) {
            const result = yield call(getUserInfo);

            if (result) {
                updateLocalUserInfo(result);
                const userInfo = getLocalUserInfo();
                yield put({
                    type: 'save',
                    payload: {
                        userInfo: {
                            ...userInfo
                        }
                    }
                });
            }
        },
        * initUserInfo(_, { put }) {
            const token = getLocalToken();
            const userInfo = getLocalUserInfo();

            yield put({
                type: 'save',
                payload: {
                    token
                }
            });

            if (token && !userInfo.username) {
                yield put({
                    type: 'getUserInfo'
                });
            }
        },
        * login({ payload }, { call, put }) {
            const { email, password } = payload;
            const [error, response] = yield call(request, {
                method: 'post',
                url: API.account.login,
                params: {
                    email,
                    password
                }
            });
            if (error) {
                notification.error(error);
                return;
            }
            const { token } = response;
            yield put({
                type: 'save',
                payload: {
                    token
                }
            });
            updateLocalToken(token);
            yield put({
                type: 'user/getUserInfo'
            });
            historyPush('/');
        },
        * logout(_, { put }) {
            yield put({
                type: 'save',
                payload: {
                    userInfo: {}
                }
            });
            clearLocalToken();
            clearLocalUserInfo();
        },
        * register({ payload }, { call, put }) {
            const { email, password, username } = payload;
            const [error, response] = yield call(request, {
                method: 'post',
                url: API.account.logon,
                params: {
                    username,
                    email,
                    password
                }
            });
            if (error) {
                notification.error(error);
                return;
            }
            const { token } = response;
            yield put({
                type: 'save',
                payload: {
                    token
                }
            });
            updateLocalToken(token);
            yield put({
                type: 'getUserInfo'
            });
            historyPush('/');
        },
        * forget({ payload }, { call }) {
            const { email } = payload;
            const [error] = yield call(request, {
                method: 'post',
                url: API.account.forget,
                params: {
                    email,
                }
            });
            if (error) {
                notification.error(error);
            }
        },
    },
    subscriptions: {
        setup({ dispatch }) {
            dispatch({
                type: 'initUserInfo'
            });
        }
    }
};

export default globalModel;
