// import axios from 'axios';
// import { getLocalToken } from '../user';

export default function request() {
    return [null, { list: [] }];
}

// export default async function request(requestOptions) {
//     const {
//         params, method = 'get',
//         headers, ...otherRequestOptions
//     } = requestOptions;

//     const requestHeaders = {
//         'Content-Type': 'application/json',
//         ...headers,
//     };

//     const token = getLocalToken();

//     if (token) {
//         requestHeaders.token = token;
//     }

//     const httpMethod = method.toLowerCase();
//     const axiosOptions = {
//         method,
//         headers: requestHeaders,
//         ...otherRequestOptions
//     };
//     if (httpMethod === 'post' || method === 'put') {
//         axiosOptions.data = params;
//     } else {
//         axiosOptions.params = params;
//     }

//     let response;
//     try {
//         response = await axios(axiosOptions);
//     } catch (e) {
//         return [e.message];
//     }
//     if (response.status !== 200) {
//         return [response.statusText];
//     }

//     const responseData = response.data;
//     if (!responseData) {
//         return ['网络连接错误！'];
//     }
//     const { data, status, desc } = responseData;
//     if (status) {
//         return [desc];
//     }

//     return [null, data];
// }
