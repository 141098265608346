import React from 'react';
import App from './pages';
import app from './store';
import globalModel from './models/global';

export default function start() {
    app.model(globalModel);
    app.router(({ history }) => <App app={app} history={history} />);
    app.start('#root');
}
