import { createBrowserHistory } from 'history';

export const browserHistory = createBrowserHistory();

export function historyPush(path) {
    browserHistory.push(path);
}

export function historyReplace(path) {
    browserHistory.replace(path);
}

export function historyGoBack() {
    browserHistory.goBack();
}
