import css from 'styled-jsx/css';
import { colorDanger, colorSuccess, colorInfo } from '@/utils/constants/colors';

const globalNotificationStyles = css.global`
.react-notification-root {
    display: fixed;
    top: 0;
    left: 0;
}

.notification-item {
    background: #fff;
    color: #4a4a4a;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 18px;
    min-height: 60px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
}

.notification-item .notification-content {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 20px 24px;
}

.notification-item .notification-close {
    background: #fff;
    width: 16px;
    height: 16px;
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .7;
}
.notification-item .notification-close:after {
    font-size: 16px;
    margin-bottom: 1px;
    position: relative;
    top: 0;
    left: 0;
    transform: none;
}

.notification-item .notification-message {
    flex: 1;
    margin-right: 16px;
    color: #fff;
}

.notification-item.notification-danger {
    background: ${colorDanger};
}
.notification-item.notification-danger .notification-close:after {
    color: ${colorDanger};
}
.notification-item.notification-success {
    background: ${colorSuccess};
}
.notification-item.notification-success .notification-close:after {
    color: ${colorSuccess};
}
.notification-item.notification-info {
    background: ${colorInfo};
}
.notification-item.notification-info .notification-close:after {
    color: ${colorInfo};
}
`;

export default globalNotificationStyles;
