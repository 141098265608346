import css from 'styled-jsx/css';

const globalModalStyles = css.global`
    .custom.ant-modal {
        width: 580px !important;
    }

    .custom.ant-modal .ant-modal-body {
        padding: 48px 64px !important;
    }

    .custom.ant-modal .ant-modal-content {
        border-radius: 32px;
    }

    .custom.ant-modal .ant-modal-header {
        border-radius: 32px 32px 0 0;
    }

    .custom.ant-modal .ant-modal-footer {
        border-radius: 0 0 32px 32px;
    }
    
`;

export default globalModalStyles;
