const routes = [
    {
        path: '/',
        component: () => import('./home'),
        models: () => [
            import('@/models/home')
        ]
    },
    {
        path: '/cover-market',
        component: () => import('./cover-market'),
        models: () => [
            import('@/models/coverMarket')
        ]
    },
    {
        path: '/cover-detail/:id',
        component: () => import('./cover-detail'),
        models: () => [
            import('@/models/coverDetail')
        ]
    },
    {
        path: '/sticker-market',
        component: () => import('./sticker-market'),
        models: () => [
            import('@/models/stickerMarket')
        ]
    },
    {
        path: '/sticker-detail/:id',
        component: () => import('./sticker-detail'),
        models: () => [
            import('@/models/stickerDetail')
        ]
    },
    {
        path: '/profile/:id',
        component: () => import('./profile'),
        models: () => [
            import('@/models/profile')
        ]
    },
    {
        path: '/notification',
        component: () => import('./notification'),
        models: () => [
            import('@/models/notification')
        ]
    },
    {
        path: '/cart',
        component: () => import('./cart'),
        models: () => [
            import('@/models/cart/cartList'),
            import('@/models/cart/orderHistory'),
            import('@/models/cart/paymentInfo'),
            import('@/models/cart/wishList'),
        ]
    },
    {
        path: '/create-db',
        component: () => import('./create-db'),
        models: () => [
        ]
    },
    {
        path: '/login',
        component: () => import('./login'),
    },
    {
        path: '/register',
        component: () => import('./register'),
    },
    {
        path: '/forget-password',
        component: () => import('./forget-password')
    },
    {
        path: '/faq',
        component: () => import('./faq')
    },
    {
        path: '/privacy',
        component: () => import('./privacy')
    },
    {
        path: '/terms',
        component: () => import('./terms')
    },
    {
        path: '/contact-us',
        component: () => import('./contact-us')
    },
    {
        path: '/about-us',
        component: () => import('./about-us')
    },
];

export default routes;
