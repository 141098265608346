import css from 'styled-jsx/css';
import { colorText } from '@/utils/constants/colors';

const globalDropdownStyles = css.global`
    .ant-dropdown {
        width: 170px !important;
        padding: 10px 0 !important;
    }

    .ant-dropdown-menu {
        padding: 16px 0 !important;
        border-radius: 8px !important;
    }

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        padding: 8px 20px !important;
        font-size: 14px !important;
        font-weight: bold !important;
        line-height: 18px !important;
    }

    .ant-dropdown-menu-item > a,
    .ant-dropdown-menu-submenu-title > a {
        color: ${colorText} !important;
    }
`;

export default globalDropdownStyles;
