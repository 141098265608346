import css from 'styled-jsx/css';
import { colorTitle, colorBorder, colorLightBorder } from '@/utils/constants/colors';

const globalCollapse = css.global`
.custom.ant-collapse {
    border: none;
    background: #fff;
}

.custom.ant-collapse > .ant-collapse-item {
    border-color: ${colorLightBorder};
    padding-bottom: 40px;
    margin-bottom: 40px;
}

.custom.ant-collapse .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    color: ${colorBorder};
    font-weight: bold;
    font-size: 28px;
    letter-spacing: 0;
    line-height: 40px;
    padding: 0;
}


.custom.ant-collapse .ant-collapse-icon-position-right > .ant-collapse-item-active > .ant-collapse-header {
    color: ${colorTitle};
}

.custom.ant-collapse .ant-collapse-content.ant-collapse-content-active {
    border-top: none;
}

.custom.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
    padding-top: 24px;
}

`;

export default globalCollapse;
