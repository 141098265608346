const server = `http://54.254.78.242:9002/api`;

const API = {
    account: {
        login: `${server}/account/login`,
        logon: `${server}/account/logon`,
        forget: `${server}/account/forget`,
        thirdpart: `${server}/account/thirdpart`
    },
    home: {
        banner: `${server}/index/banner`,
        goods: `${server}/index/goods`,
        stories: `${server}/index/stories`,
        artists: `${server}/index/artists`,
        stickers: `${server}/index/stickers`
    },
    user: {
        info: `${server}/user/info`,
        detail: `${server}/user/detail`
    },
    goods: {
        list: `${server}/goods/list`
    },
    sticker: {
        list: `${server}/sticker/list`
    },
    tools: {
        upload: `${server}/tools/upload`
    }
};

export default API;
