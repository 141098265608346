import css from 'styled-jsx/css';
import {
    colorBtn, colorBorder, colorWhite, colorText
} from '@/utils/constants/colors';

const globalCheckBox = css.global`
    .custom.ant-checkbox-wrapper {
        margin-right: 48px;
        color: ${colorText};
    }
    .custom.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${colorBtn};
        border-color: ${colorBtn};
    }
    .custom.ant-checkbox-wrapper .ant-checkbox-inner {
        border-color: ${colorBorder};
    }
    .custom.ant-checkbox-wrapper .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .custom.ant-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner,
    .custom.ant-checkbox-wrapper .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: ${colorBtn};
    }
    .custom.ant-checkbox-wrapper .ant-checkbox-checked::after {
        border: none;
    }
    .custom.ant-checkbox-wrapper .ant-checkbox + span {
        padding-left: 12px;
        padding-right: 0px;
    }
    .custom.ant-checkbox-wrapper .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: ${colorWhite};
        transform: translate(0%, -50%);
        width: 8px;
        height: 2px;
    }
    .custom.ant-checkbox-wrapper .ant-checkbox-disabled + span {
        color: ${colorText};
    }
`;

export default globalCheckBox;
