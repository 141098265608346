import dva from 'dva';
import { browserHistory } from './utils/history';

const app = dva({
    history: browserHistory,
    onError: (error) => {
        console.error(error);
    }
});

export default app;
