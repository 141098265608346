import css from 'styled-jsx/css';
import { colorLightBorder, colorText, colorWhite } from '@/utils/constants/colors';

const globalInputStyles = css.global`
input:-internal-autofill-selected  {
    -webkit-appearance: menulist-button;
    background-color: ${colorWhite} !important;
    background-image: none !important;
    color: ${colorText} !important;
}

textarea {
    resize: none !important;
}

.custom.ant-input-affix-wrapper {
    display: flex;
    align-items: center;
    border-color: ${colorLightBorder} !important;
    border-radius: 32px;
    padding: 0 32px;
}

.custom .ant-input-prefix {
    margin-right: 12px;
}

.custom.ant-input,
.custom .ant-input {
    outline: 0;
    border-color: ${colorLightBorder};
    height: 64px;
    border-radius: 32px;
    padding: 22px 24px;
    color: ${colorText};
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    width: 100%;
}

.custom.ant-input[type="password"],
.custom .ant-input[type="password"] {
    letter-spacing: 6px;
}

.custom.ant-input:focus,
.custom.ant-input:hover,
.custom .ant-input:focus,
.custom .ant-input:hover {
    border-color: ${colorLightBorder};
}

.custom.ant-input:focus,
.custom.ant-input-focused,
.custom.ant-input-affix-wrapper:focus,
.custom.ant-input-affix-wrapper-focused,
.custom .ant-input:focus,
.custom .ant-input-focused,
.custom .ant-input-affix-wrapper:focus,
.custom .ant-input-affix-wrapper-focused  {
    border-right-width: 1px !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.custom.ant-input::placeholder,
.custom .ant-input::placeholder {
  opacity: 0.5;
  color: ${colorText};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

textarea.ant-input.custom,
.custom textarea.ant-input {
    padding: 20px 24px;
    min-height: 160px;
}

.ant-input-sm {
}

.ant-input-lg {
}

`;

export default globalInputStyles;
